import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
  IFRAME_ID,
  IFRAME_URL,
  SHAV_WRAPPER_ID,
} from '../src/shared/constants';
import { SharedEvents } from '../src/shared/events';
// import { initEventListeners } from './events';
import { iFrameRemoveStyle, iFrameStyle, wrapperStyle } from './shim.style';

export const CHANGE_CONTAINER_CLASS = 'CHANGE_CONTAINER_CLASS';
export const CHANGE_CONTAINER_CLASS_DONE = 'CHANGE_CONTAINER_CLASS_DONE';

export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';

export const DOMAIN_NOT_ALLOWED = 'DOMAIN_NOT_ALLOWED';

export const BOOTSTRAP_DONE = 'BOOTSTRAP_DONE';

declare global {
  interface Window {
    shavatar: Shavatar;
  }
}

export interface ShavatarOptions {
  productId: string | number;
  debug?: boolean;
  enableAnalytics?: boolean;
  noCupSize?: boolean;
  language?: string;
  privacyPolicyUrl?: string;
  privacyPolicyShopName?: string;
  addToCartFunc?: (variant?) => void;
  onGoToCart?: () => void;
  colors: {
    primary: string;
  };
  productVariants: {
    variantId: string | number;
    inventory_quantity: number | undefined;
    optionsList: string | undefined;
  }[];
}

class Shavatar {
  private debugMode: boolean;
  private domainAllowed: boolean;
  private iframe?: HTMLIFrameElement = undefined;
  public clientId: string = '';
  public options: ShavatarOptions;
  public onload: () => void;

  constructor(onloadFunc = () => {}) {
    this.debugMode = false;
    this.onload = onloadFunc;
    this.domainAllowed = true; // optimistically assume for now

    // Init tracking
    this.initializeGtag();
  }

  // PUBLIC API

  init = (clientId: string, options: ShavatarOptions) => {
    this.logDebugMessage('init widget');
    this.clientId = clientId;
    this.options = options;
    if (!clientId) {
      console.error(
        'no client id: please provide a valid client id to initialize the flow',
      );
    }
    if (!options.productId) {
      console.error(
        'no product id: please provide a valid product id to initialize the flow',
      );
      return;
    }
    this.initializeIframe();
    this.mountIframe();
    if (options?.debug) {
      this.debug();
    }
    this.onload();

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    if (typeof gtag === 'function') {
      gtag('event', 'init_shav', {
        send_to: 'shav',
        event_label: 'Opened shav widget',
      });
    }
  };

  debug = () => {
    this.debugMode = !this.debugMode;
    console.info(
      `[SHAVATAR] debug mode ${this.debugMode ? 'enabled' : 'disabled'}`,
    );
    this.iframe?.contentWindow?.postMessage(
      { type: SET_DEBUG_MODE, value: this.debugMode },
      '*',
    );
  };

  // PRIVATE METHODS

  onCloseWidget = () => {
    this.logDebugMessage('Closing widget');

    if (this.iframe) {
      enableBodyScroll(this.iframe);
    }
    document
      .getElementById(SHAV_WRAPPER_ID)
      .setAttribute('style', iFrameRemoveStyle);
  };

  onCloseWidgetAddToCart = (variant) => {
    this.logDebugMessage('Adding to cart');

    // Track conversion
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    if (typeof gtag === 'function') {
      gtag('event', 'add_to_cart_shav', {
        send_to: 'shav',
        event_label: 'Add to Cart with widget',
      });
      // console.log('GTAG', 'add_to_cart_shav');
    }

    if (
      this.options?.addToCartFunc != undefined &&
      typeof this.options?.addToCartFunc === 'function'
    )
      this.options.addToCartFunc(variant);
  };

  onGoToCart = () => {
    this.logDebugMessage('Going to cart');
    if (
      this.options?.onGoToCart != undefined &&
      typeof this.options?.onGoToCart === 'function'
    )
      this.options.onGoToCart();
  };

  receiveMessage = (event: MessageEvent) => {
    this.logDebugMessage('received message with data:', event);

    if (!!event && !!event.data && !!event.data.type) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      switch (event.data.type) {
        case SharedEvents.JS_LOADED:
          this.iframe.contentWindow.postMessage(
            {
              type: SharedEvents.INIT_WIDGET,
              value: {
                widget: 'sizeAdvice',
                clientId: this.clientId,
                topHost: window.location.host,
                options: {
                  ...this.options,
                  addToCartFunc: undefined,
                  onGoToCart: undefined,
                },
              },
            },
            '*',
          );
          break;
        case SharedEvents.SET_COOKIE:
          document.cookie = event.data.value;
          break;
        case SharedEvents.CLOSE_WIDGET:
          this.onCloseWidget();
          break;
        case SharedEvents.ADD_TO_CART:
          this.onCloseWidgetAddToCart(event.data?.payload?.selectedVariant);
          break;
        case SharedEvents.CART:
          this.onGoToCart();
          break;
        case SharedEvents.ABOUT_YOU:
          gtag('event', 'ABOUT_YOU', {
            send_to: 'shav',
            event_label: 'ABOUT_YOU',
          });
          console.log('ABOUT_YOU');
          break;
        case SharedEvents.MEASUREMENTS:
          gtag('event', 'MEASUREMENTS', {
            send_to: 'shav',
            event_label: 'MEASUREMENTS',
          });
          console.log('MEASUREMENTS');

          break;
        case SharedEvents.RECOMMENDATION:
          gtag('event', 'RECOMMENDATION', {
            send_to: 'shav',
            event_label: 'RECOMMENDATION',
          });
          console.log('RECOMMENDATION');

          break;
        case BOOTSTRAP_DONE:
          this.handleBootstrapDone();
          break;
        default:
          break;
      }
    }
  };

  initializeIframe = () => {
    this.logDebugMessage('Initializing iframe');

    if (!document.getElementById(IFRAME_ID)) {
      this.logDebugMessage('iframe does not exist, creating...');
      const iframe = document.createElement('iframe');
      iframe.src = `${IFRAME_URL}?clientId=${this.clientId}`;
      iframe.id = IFRAME_ID;
      iframe.setAttribute('style', iFrameStyle);

      this.iframe = iframe;
    } else {
      this.logDebugMessage('iframe does exist, posting init message');
      this.iframe?.contentWindow?.postMessage(
        {
          type: SharedEvents.INIT_WIDGET,
          value: {
            widget: 'sizeAdvice',
            clientId: this.clientId,
            topHost: window.location.host,
            options: {
              ...this.options,
              addToCartFunc: undefined,
              onGoToCart: undefined,
            },
          },
        },
        '*',
      );
    }

    if (this.iframe) {
      disableBodyScroll(this.iframe);
    }
  };

  mountIframe = () => {
    this.logDebugMessage('mounting iframe to HTML DOM');

    let wrapper = document.getElementById(SHAV_WRAPPER_ID);
    if (!document.getElementById(IFRAME_ID) && !wrapper && this.iframe) {
      this.logDebugMessage('attaching "message" event listener to window');
      window.addEventListener('message', this.receiveMessage, false);
      this.logDebugMessage('creating wrapper div');
      const wrapper = document.createElement('div');
      wrapper.id = SHAV_WRAPPER_ID;
      wrapper.style.zIndex = '1200';
      wrapper.setAttribute('style', wrapperStyle);
      this.logDebugMessage('adding iframe to wrapper');
      wrapper.appendChild(this.iframe);
      this.logDebugMessage('adding wrapper to DOM');
      document.body.appendChild(wrapper);
    } else {
      this.logDebugMessage('wrapper and iframe already exist in HTML DOM');
      wrapper.setAttribute('style', wrapperStyle);
    }
  };

  // Init CRO GA4 tag
  // Use groups to split GA4 traffic with same gtag function
  initializeGtag() {
    // console.log('GTAG', 'init');

    var script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-YSEW2XLD9J';

    script1.onload = function () {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-YSEW2XLD9J', { groups: 'shav' });
      // console.log('GTAG', 'init_finish');

      // console.log('GTAG', 'init_evt_listener_init');
      // console.log('GTAG, pathname', window.location.pathname);

      // Listen for productView
      if (
        typeof gtag === 'function' &&
        /\/products\//.test(window.location.pathname)
      ) {
        gtag('event', 'product_view', {
          send_to: 'shav',
          event_label: 'Product view',
        });
        // console.log('GTAG', 'product_view');
      }

      // Listen for addToCartWithShav
      // Listen for shavInit --> direct gtag("event", "shavInit") in init func
      // zie index.ts
      // Listen for normale addToCart --> maak universele regel op basis van link of button elem name, class, id
      var addToCartLinks = document.querySelectorAll(
        'a[href*="/cart/add"], a[href*="/cart"], a[name="add"], button[name="add"], [data-product-add], [data-action="add-to-cart"], button.add-to-cart, input[type="submit"].add-to-cart, a#AddToCart, a.AddToCart',
      );
      if (addToCartLinks.length > 0) {
        for (var i = 0; i < addToCartLinks.length; i++) {
          var link = addToCartLinks[i];
          link.addEventListener('click', function () {
            if (typeof gtag === 'function') {
              gtag('event', 'add_to_cart', {
                send_to: 'shav',
                event_label: 'Add to Cart',
              });
              // console.log('GTAG', 'add_to_cart');
            }
          });
        }
      }

      // Listen for checkout_success
      var successPageRegex = /\/checkouts\/(.+)\/(thank_you|post_purchase)/;
      var successPageSelectors = ['body.order-confirmation'];
      var isSuccessPage =
        successPageRegex.test(window.location.pathname) ||
        successPageSelectors.some(function (selector) {
          return document.querySelector(selector) !== null;
        });

      if (isSuccessPage) {
        if (typeof gtag === 'function') {
          gtag('event', 'purchase', {
            send_to: 'shav',
            event_label: 'Purchase',
          });
          // console.log('GTAG', 'purchase');
        }
      }

      // console.log('GTAG', 'init_evt_listener_finish');
    };
    document.head.appendChild(script1);
  }

  // DEBUG
  handleBootstrapDone = () => {
    this.logDebugMessage('bootstrap done');

    const shavatarApi = window.shavatar;
    shavatarApi.debug = this.debug;
    window.shavatar = shavatarApi;
  };

  logDebugMessage = (...args) => {
    if (this.debugMode) {
      console.log('[SHAVATAR]: ', ...args);
    }
  };
}

export default ((win: unknown) => {
  const window = win as Window;
  const onloadFunc =
    window.shavatar?.onload && typeof window.shavatar.onload === 'function'
      ? window.shavatar.onload
      : () => {};
  const shavatar = new Shavatar(onloadFunc);
  window.shavatar = shavatar;
})(global);
